import { render, staticRenderFns } from "./cooperation-info.vue?vue&type=template&id=6863a886&scoped=true&"
import script from "./cooperation-info.vue?vue&type=script&lang=js&"
export * from "./cooperation-info.vue?vue&type=script&lang=js&"
import style0 from "./cooperation-info.vue?vue&type=style&index=0&id=6863a886&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6863a886",
  null
  
)

export default component.exports