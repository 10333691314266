//我司合作情况
<template>
  <div>
    <!-- 标题 -->
    <div>
      <div>
      <font class="font"><span v-if="!$attrs.disabled">*</span></font><span style="font-size:14px">我司合作情况</span><span
        v-if="$attrs.value!=='NO_COOPERATION'"
        class="detailed"
        @click="visible=true"
        style="font-size:14px"
        >查看合作明细</span
      >
    </div>
    <!-- 选项 -->
    <el-radio-group v-bind="$attrs" v-on="$listeners" :disabled="$attrs.disabled">
      <el-radio
        v-for="(item, index) of ($attrs.options)"
        :label="item.value"
        :key="index"
        >{{ item.label }}
      </el-radio>
    </el-radio-group>
    </div>
    <!-- <el-radio v-else   :disabled="true" v-bind="$attrs" :label="item.value">{{item.value}}</el-radio> -->
    <!-- 合作明细弹窗 -->
    <base-dialog :visible.sync="visible" :showFooter="false" title="合作明细">
    <div class="totalFinancing">融资总额（元）：<span>{{financeTotal}}</span></div>
      <base-table
        :columns="cooperationColumns"
        :showPage="false"
        :tableAttrs="{
          data: cooperationData,
          stripe: false,
          height: '620',
        }"
      :loadCount="loadCount"
        :dataSource.sync="cooperationData"
        :is-caculate-height="false"
        @pageIndexChange='pageIndexChange'
        @pageSizeChange='pageSizeChange'
      >
       <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      </base-table>
      <template slot="footer">
        <base-button
          label="关 闭"
          type="default"
          @click="visible = false"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { cooperationTable } from '../utils/make-config'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'

export default {
  components: { baseDialog, BaseButton, BaseTable },
  data () {
    return {
      visible: false,
      financeTotal: '', // 融资总额
      cooperationData: [], // 表格数据
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadCount++
    })
  },
  watch: {
    visible (val) {
      if (val) {
        this.getCooperationDetail()
      }
    }
  },
  computed: {
    cooperationColumns () {
      return cooperationTable(this)
    }
  },
  methods: {
    // 查合作明细初始化书
    getCooperationDetail () {
      // cooperationType: this.$attrs.value
      financeAdmittanceApi.getCooperationDetail({ gysId: this.$attrs.gysId }).then(res => {
        this.cooperationData = res.data?.cooperationDetailVOS
        this.financeTotal = res.data?.financeTotal.toLocaleString('en-US', { minimumFractionDigits: 2 })
      })
    },
    pageIndexChange (data) {
      this.queryParas.pageIndex = data
      this.loadCount++
    },
    pageSizeChange (data) {
      this.queryParas.pageSize = data
      this.loadCount++
    }
  }
}
</script>
<style lang="scss" scoped>
.font {
  color: red;
  padding-right: 4px;
}
.detailed {
  color: red;
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
}
.totalFinancing{
  padding: 10px;
}
</style>
