import BaseSelect from '@/components/common/base-select/base-select.vue'
import termOfValidity from '@/components/packages/term-of-validity/term-of-validity.vue'
import { getDictLists, filterMultiple } from '@/filters/fromDict'
import fileUpdate from '../components/file-update'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import cooperationInfo from '../components/cooperation-info.vue'
import IsAccountRadio from '../components/is-account-radio.vue'
import { formatDate } from '@/utils/auth/common'
import Storage from '@/utils/storage'
import { validatorRequiredTerm } from '@/utils/validate'
import { PSBC_KEY_ID } from '@/config/constant'

// 融资申请信息
export const financingApplicationConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  // 融资期限校验
  // const validatorFinanceTerm = (rule, value, callback) => {
  //   if (value) {
  //     if (!content.currentData.financeTermUnit) {
  //       callback(new Error('请选择日期格式'))
  //     } else {
  //       callback()
  //     }
  //   } else {
  //     if (content.currentData.financeTermUnit) {
  //       callback(new Error('请输入日期'))
  //     } else {
  //       callback()
  //     }
  //   }
  // }
  const validatorApplyAmount = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入融资金额'))
    } else if (value < 10000) {
      callback(new Error('融资金额需大于等于10000元'))
    } else {
      callback()
    }
  }
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      rules: [{ required: false, message: '请输入业务编号', trigger: 'change' }],
      attrs: {
        maxlength: 20,
        disabled: true,
        placeholder: '请输入'
      },
      cols
    },
    {
      label: '融资产品',
      rules: [{ required: false, message: '请输入融资产品', trigger: 'change' }],
      prop: 'finaaceProductName',
      attrs: {
        maxlength: 50,
        disabled: true,
        placeholder: '请输入'
      },
      cols
    },
    {
      label: '供应商名称',
      prop: 'gysCompanyName',
      rules: [{ required: false, message: '请输入供应商名称', trigger: 'change' }],
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols: { lg: 10 }
    },
    {
      label: '统一社会信用代码',
      rules: [{ required: false, message: '请输入统一社会信用代码', trigger: 'change' }],
      prop: 'creditCode',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols
    },
    {
      label: '融资金额(元)',
      rules: [{ required: true, validator: validatorApplyAmount, trigger: 'blur' }],
      prop: 'applyAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Y'
      },
      cols,
      tag: AmountInput
    },
    {
      label: '融资期限',
      prop: 'financeTerm',
      rules: [
        { required: true, validator: validatorRequiredTerm(content.currentData.financeTermUnit), trigger: ['change', 'blur'] }
        // { validator: validateTerm(content.currentData.financeTermUnit), trigger: ['change', 'blur'] }
      ],
      attrs: {
        dictType: 'MM',
        placeholder: '请输入有效期',
        maxlength: 5,
        type: 'ZS',
        validTimeUnit: content.currentData.financeTermUnit
      },
      on: {
        update (val) {
          content.changeFinanceTermUnit()
          content.changeValidTimeUnit(val)
        }
      },
      cols,
      tag: termOfValidity

    },
    {
      label: '综合融资利率(%)',
      rules: [{ required: true, message: '请输入综合融资利率', trigger: 'blur' }],
      prop: 'financeRate',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      tag: AmountInput,
      cols,
      on: {
        change (val) {
          content.changeFinanceRate(val * 1)
        }
      }
    },
    {
      label: '业务所属区域',
      prop: 'areaId',
      rules: [{ required: true, message: '请选择业务所属区域', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: getDictLists('BUSINESS_AREA'),
        selectedField: ['dictId', 'dictName']
      },
      cols,
      tag: BaseSelect
    },
    {
      label: '供应商类型',
      prop: 'supplierType',
      rules: [{ required: true, message: '请选择供应商类型', trigger: 'change' }],
      attrs: {
        placeholder: '请输入',
        options: getDictLists('SUPPLIER_TYPE'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      cols
    },
    {
    // 我司合作情况
      prop: 'cooperationType',
      // rules: [{ required: true, message: '请选择我司合作情况', trigger: 'change' }],
      attrs: {
        placeholder: '请输入',
        options: content.cooperationType,
        gysId: content.currentData.gysId
      },
      cols,
      tag: cooperationInfo
    },
    {
      label: '资金方',
      prop: 'capitalSideId',
      rules: [{ required: false, message: '请选择资金渠道', trigger: 'change' }],
      attrs: {
        placeholder: '请输入',
        options: content.providertData,
        selectedField: ['keyId', 'capitalAndProductName']
      },
      cols,
      tag: BaseSelect,
      on: {
        change (val) {
          content.changeCapitalSide(val)
        }
      }
    },
    {
      label: '资方成本价格(%)',
      rules: [{ required: false, message: '请输入成本价格', trigger: 'blur' }],
      prop: 'costPrice',
      attrs: {
        placeholder: '请输入',
        type: 'BL',
        // 建设银行、中小企、邮储银行不能编辑资金方成本价格
        disabled: content.currentData.capitalSideId === '2111091147472850001' || content.currentData.capitalSideId === '2112280944412630001' || content.currentData.capitalSideId === PSBC_KEY_ID

      },
      tag: AmountInput,
      cols,
      on: {
        change (val) {
          content.changeCostPrice(val * 1)
        }
      }

    },
    {
      label: Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付' ? '综合质押率(%)' : '折价率(%)',
      showTip: '折价率 = 标的资产对应融资金额总和 / 转让价值金额总和',
      prop: 'totalPledgeRate',
      rules: [{ required: false, message: '请输入综合质押率', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        type: 'BL',
        disabled: true
      },
      tag: AmountInput,
      cols
    },
    {
      label: '服务费率（%）',
      rules: [{ required: false, message: '请输入服务费率', trigger: 'blur' }],
      prop: 'serviceRate',
      attrs: {
        placeholder: '请输入',
        // disabled: true,
        type: 'BL'
      },
      hidden: content.hiddenServiceRate,
      tag: AmountInput,
      cols
    },
    {
      label: '是否同意账户变更',
      prop: 'changeOrNot',
      attrs: {
        options: [
          { value: '1', label: '是' },
          { value: '0', label: '否' }
        ]
      },
      tag: IsAccountRadio,
      cols
    }

  ]
}
// 标的资产--资产信息
export const assetInfoTable = (content) => {
  console.log(content.financingApplicationData, 'content.financingApplicationData')
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    // {
    //   label: '付款单',
    //   prop: 'easNo',
    //   HiddenOverflow: true,
    //   minWidth: '150px',
    //   isHidden: (Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付')
    // },
    {
      label: '合同名称',
      prop: 'finContractId',
      HiddenOverflow: true,
      minWidth: '150px'
    },
    {
      label: '合同编号',
      prop: 'finContractCode',
      HiddenOverflow: true
    },
    {
      label: '实际合同名称',
      prop: 'finContractName',
      HiddenOverflow: true
    },
    {
      label: '项目公司名称',
      prop: 'xmgsName',
      HiddenOverflow: true
    },
    {
      label: '合同对价(元)',
      prop: 'finContractAmount',
      HiddenOverflow: true
      // formatter: row => {
      //   return (row.finContractAmount ? row.finContractAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      // }
    },
    {
      label: '发票编号',
      prop: 'invoiceNo',
      HiddenOverflow: true
    },
    {
      label: '转让价值(元)',
      prop: 'pledgeValue',
      HiddenOverflow: true
      // formatter: row => {
      //   return (row.pledgeValue ? row.pledgeValue.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      // }
    },
    {
      label: '对应融资额(元)',
      prop: 'relLoanAmount',
      HiddenOverflow: true
      // formatter: row => {
      //   return (row.relLoanAmount ? row.relLoanAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      // }
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate',
      HiddenOverflow: true
    },
    {
      label: '操作',
      prop: 'action',
      width: '60px'
    }
  ]
}

export const paymentForm = (content) => {
  const startOptions = {
    disabledDate (time) {
      return time.getTime() <= new Date('1989-12-30')
    }
  }
  return [
    {
      label: '付款申请编号',
      prop: 'easNo',
      attrs: {
        placeholder: '请输入'
      }
    },
    // {
    //   label: '供应商名称',
    //   prop: 'nsrsbh',
    //   attrs: {
    //     placeholder: '请输入'
    //   }
    // },
    {
      label: '合同名称',
      prop: 'contractName',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '时间',
      prop: 'changeTime',
      attrs: {
        placeholder: '请选择',
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间',
        valueFormat: 'yyyy-MM-dd',
        pickerOptions: startOptions,
        clearable: true
      },
      tag: 'el-date-picker'
    }
  ]
}
// 付款单表格
export const paymentTable = (content) => {
  return [
    {
      type: 'selection',
      width: '60px'
    },

    {
      label: '付款申请编号',
      prop: 'easNo',
      minWidth: '150px',
      align: 'left'
    },
    {
      label: '供应商名称',
      prop: 'gysName',
      minWidth: '100px',
      align: 'left'
    },
    {
      label: '工程项目',
      prop: 'projectName',
      minWidth: '100px',
      align: 'left'
    },
    {
      label: '合同名称',
      prop: 'contractName',
      minWidth: '100px',
      align: 'left'
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      minWidth: '100px',
      align: 'left'
    },
    {
      label: '合同金额',
      prop: 'contractAmount',
      minWidth: '100px',
      align: 'right',
      formatter: row => {
        return (row.contractAmount ? row.contractAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '申报金额（产值）',
      prop: 'paymentApplyAmount',
      minWidth: '150px',
      align: 'right',
      formatter: row => {
        return (row.paymentApplyAmount ? row.paymentApplyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '累计完成工程产值',
      prop: 'outputTotalAmount',
      minWidth: '150px',
      align: 'right',
      formatter: row => {
        return (row.outputTotalAmount ? row.outputTotalAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '应付金额',
      prop: 'paymentOrder',
      minWidth: '100px',
      align: 'right',
      formatter: row => {
        return (row.paymentOrder ? row.paymentOrder.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '已付金额',
      prop: 'paidAmount',
      minWidth: '100px',
      align: 'right',
      formatter: row => {
        return (row.paidAmount ? row.paidAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },

    {
      label: '操作',
      prop: 'action',
      minWidth: '60px',
      fixed: 'right'
    }
  ]
}

// 标的资产--项目信息
export const projectInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '项目公司名称',
      prop: 'xmgsName',
      minWidth: '200px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '项目城市',
      prop: 'city',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '金地控股比例（%）',
      prop: 'shareholdingRatio',
      minWidth: '90px',
      'show-overflow-tooltip': true
    },
    {
      label: '金地控股主体',
      prop: 'controllingEntity',
      minWidth: '150px'
    }
  ]
}

// 备案条款数据
export const filingTermsTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '备案条款',
      prop: 'filingTerms',
      minWidth: '200px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '审核结果',
      prop: 'approveResult',
      minWidth: '150px'
    },
    {
      label: '操作',
      prop: 'action',
      minWidth: '80px'
    }
  ]
}

// 附件
export const accessoriesTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '文件类型',
      prop: 'documentName',
      minWidth: '180px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '是否已提供',
      // 'render-header': renderHeader,
      prop: 'isProvide',
      with: '90px'
    },
    {
      label: '文件个数',
      prop: 'fileNum',
      with: '90px'
    },
    {
      label: '文件名称',
      prop: 'fileListDetailVOS',
      minWidth: '200px',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '未收集原因',
      prop: 'noReasonProvided',
      with: '140px',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '操作',
      prop: 'action',
      with: '80px'
    }
  ]
}

// 尽调安排
export const arrangementFormData = (content) => {
  const loanOptions = {
    disabledDate (time) {
      return time.getTime() <= new Date('1990-01-01')
    }
  }
  return [
    {
      label: '预计尽调时间',
      prop: 'expectDueDiligenceDate',
      rules: [{ required: true, message: '请选择预计尽调时间', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        'value-format': 'timestamp',
        type: 'date',
        pickerOptions: loanOptions
      },
      tag: 'el-date-picker'
    },
    {
      label: '预计尽调地点',
      rules: [{ required: true, message: '请输入', trigger: 'blur' }],
      prop: 'expectDueDiligenceAddress',
      attrs: {
        placeholder: '请输入',
        maxlength: 200
      }
      // on: {
      //   input () {
      //     console.log(111)
      //   },
      //   change: (data) => {
      //     console.log(content, data, '预计尽调地点')
      //   }
      // }
    },
    {
      label: '预计上会时间',
      rules: [{ required: true, message: '请选择预计上会时间', trigger: 'blur' }],
      prop: 'expectMeetingTime',
      attrs: {
        placeholder: '请选择',
        'value-format': 'timestamp',
        type: 'date',
        pickerOptions: loanOptions
      },
      tag: 'el-date-picker'
    }

  ]
}
// 个人担保
export const personalTabel = (content) => {
  return [
    {
      prop: 'code',
      type: 'expand'
    },
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personType',
      formatter: row => {
        return filterMultiple('PERSONNEL_TYPE', row.personType, 'String')
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 个人担保子表格
export const personalFormChildren = (content) => {
  return [

    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType.toString())
        return obj ? obj.dictName : '-'
      }
    },
    {
      label: '家庭地址',
      prop: 'address',
      formatter: row => {
        return row.address ? row.address : '--'
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增个人担保查询
export const addPersonalFrom = (content) => {
  return [
    {
      label: '姓名',
      prop: 'personnerName',
      attrs: {
        placeholder: '请输入',
        maxlength: 100
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.personnerName = data.replace(/[0-9]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '身份证',
      prop: 'idCard',
      attrs: {
        placeholder: '请输入',
        maxlength: 18
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.idCard = data.replace(/[\u4E00-\u9FA5]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 11
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.mobilePhoneNo = data.replace(/[\u4E00-\u9FA5]|[a-zA-Z]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    }
  ]
}
// 个人担保表格
export const tableConfig = (content) => {
  return [
    {
      type: 'selection',
      width: '55px'
    },

    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personTypeName'
    }
  ]
}
export const newColumns = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand',
      HiddenOverflow: true
    },
    {
      type: 'selection',
      width: '55px'
    },
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personTypeName'
    }
  ]
}
// 个人担保关系子表格
export const relationshipTableConfig = (content) => {
  return [

    {
      prop: 'implementSituation',

      width: '120px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType)
        return obj ? obj.dictName : '-'
      }
    }
  ]
}
// 新增个人担保表格
export const addTableConfig = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '人员类别',
      prop: 'personTypeCode'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增个人担保关系子表格
export const addRelationTableConfig = () => {
  return [

    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '关系',
      prop: 'contactType'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 企业担保
export const enterpriseTable = () => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'companyName',
      align: 'left'

    },
    {
      label: '统一社会信用代码',
      prop: 'nsrsbh'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增企业担保查询表单
export const addEnterpriseForm = (content) => {
  return [
    {
      label: '企业名称',
      prop: 'enterpriseName',
      attrs: {
        placeholder: '请输入',
        maxlength: 100
      },
      on: {
        input: data => {
          content.queryParas.enterpriseName = data.replace(/\s+/g, '')
        }
      }
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      attrs: {
        placeholder: '请输入',
        maxlength: 18
      },
      on: {
        input: data => {
          content.queryParas.creditCode = data.replace(/\s+/g, '')
        }
      }
    }
  ]
}
// 新增企业担保表格
export const addEnterpriseTable = (content) => {
  return [
    {
      type: 'selection',
      width: '55px'
    },
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'enterpriseName'
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode'
    }
  ]
}
// 合作明细表格
export const cooperationTable = () => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '起息日',
      prop: 'startDate',
      formatter: row => {
        return formatDate(row.startDate, 'YY-MM-DD')
      }
    },
    {
      label: '到期日',
      prop: 'endDate',
      formatter: row => {
        return formatDate(row.endDate, 'YY-MM-DD')
      }
    },
    {
      label: '平台产品',
      prop: 'productName'
    },
    {
      label: '资金方',
      prop: 'capitalSideName'
    },
    {
      label: '融资金额（元）',
      prop: 'financeAmount',
      align: 'right',
      formatter: row => {
        return (row.financeAmount ? row.financeAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '融资利率（%）',
      prop: 'financeRate',
      formatter: row => {
        return (row.financeRate ? row.financeRate.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '成本价格（%）',
      prop: 'costPrice',
      formatter: row => {
        return (row.costPrice ? row.costPrice.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '服务费率（%）',
      prop: 'serviceRate',
      formatter: row => {
        return (row.serviceRate ? row.serviceRate.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '融资状态',
      prop: 'statusName'
      // formatter: row => {
      //   const [obj] = getDictLists('FINANCING_STATE').filter(item => row.statusCode === item.dictId)
      //   return obj.dictName ? obj.dictName : ''
      // }
    }
  ]
}
// 新增修改资产信息弹窗
export const assetsInfoFrom = () => {
  const span = 8
  return [
    {
      label: '合同名称',
      rules: [{ required: true, message: '合同名称', trigger: 'change' }],
      prop: 'companyName',
      attrs: {
        placeholder: '请选择'
      },
      span,
      tag: BaseSelect
    },
    {
      label: '合同编号',
      span,
      prop: 'nsrsbh',
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      label: '实际合同名称',
      rules: [{ required: true, message: '合同名称', trigger: 'change' }],
      span,
      prop: 'nsrsbh',
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      label: '项目公司名称',
      span,
      prop: 'nsrsbh',
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      label: '合同对价（元）',
      span,
      prop: 'nsrsbh',
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      label: '转让价值（元）',
      rules: [{ required: true, message: '合同名称', trigger: 'change' }],
      span,
      prop: 'nsrsbh',
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      span,
      label: '对应融资额（元）',
      rules: [{ required: true, message: '合同名称', trigger: 'change' }],
      prop: 'nsrsbh',
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      label: '质押率（%）',
      span,
      prop: 'nsrsbh',
      attrs: {
        placeholder: '请选择'
      }
    }
  ]
}
// 文件生成
export const otherFile = (content) => {
  console.log(content.fileFormData, 'content.fileFormData')
  const span = 12
  return [
    {
      label: '文件名称',
      prop: 'fileName',
      attrs: {
        placeholder: '请生成或上传文件',
        generatefileId: content.fileFormData.fileId
      },
      span,
      tag: fileUpdate,
      rules: [{ required: true, message: '请生成或上传文件', trigger: 'change' }],
      on: {
        generate: (data) => {
          content.generateFile('generate')
        },
        upload: (data) => {
          content.fileFormData.fileId = data.fileId
        },
        preview: (data) => {
          content.previewShow()
        },
        delFile: () => {
          content.delFile()
        }
      }
    }

  ]
}
